<template>
<div>
  <div class="form-wrap">
    <div class="row">
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Campaign Date</label>
        <span class="required-asterisk">*</span>
        <br />
        <date-picker
            aria-describedby="input-feedback"
            placeholder="MM/DD/YYYY"
            class="date-picker-text"
            :class="{'mx-input-invalid': !isCampaignDateValid}"
            @change="checkDateValidation"
            v-model="campaignDate"
            value-type="format"
            format="MM/DD/YYYY"
        >
        </date-picker>
        <div v-if="!isCampaignDateValid" class="error-message">Field is required</div>
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Date Given</label><br />
        <date-picker
            aria-describedby="input-feedback"
            placeholder="MM/DD/YYYY"
            class="date-picker-text"
            v-model="date"
            value-type="format"
            format="MM/DD/YYYY"
        >
        </date-picker>
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Emails Given </label><br />
        <input
            type="number"
            v-model="emailGiven"
            class="input-field"
            placeholder="Enter number of emails given"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Sent From </label><br />
        <input
            type="text"
            v-model="sentFrom"
            class="input-field"
            placeholder="Enter sent from"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Zero Bounce</label><br />
        <input
            type="number"
            class="input-field"
            v-model="zeroBounce"
            placeholder="Enter number of zero bounces"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Duplicates</label><br />
        <input
            type="number"
            class="input-field"
            v-model="duplicate"
            placeholder="Enter number of duplicates"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Net Uploaded </label><br />
        <input
            type="number"
            class="input-field"
            v-model="notUploaded"
            placeholder="Enter net uploaded  number"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Total List</label><br />
        <input
            type="number"
            class="input-field"
            v-model="totalList"
            placeholder="Enter total list number"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">How many emails sent? </label><br />
        <input
            type="number"
            class="input-field"
            v-model="emailSent"
            placeholder="Enter number of emails sent"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Bounces</label><br />
        <input
            type="number"
            class="input-field"
            v-model="bounce"
            placeholder="Enter number of bounces"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Spams</label><br />
        <input
            type="number"
            class="input-field"
            v-model="spam"
            placeholder="Enter number of spams"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Unsubscribes</label><br />
        <input
            type="number"
            class="input-field"
            v-model="unSubscribe"
            placeholder="Enter Unsubscribed numbers"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Open Rate (%)</label><br />
        <input
            type="text"
            class="input-field"
            v-model="openRate"
            placeholder="Enter open rate %"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Clicks</label><br />
        <input
            type="number"
            class="input-field"
            v-model="click"
            placeholder="Enter number of clicks"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Registrations</label><br />
        <input
            type="number"
            class="input-field"
            v-model="selfRegistration"
            placeholder="Enter Registration number"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Phone Calls </label><br />
        <input
            type="number"
            class="input-field"
            v-model="phoneCall"
            placeholder="Enter number of phone calls"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Emails </label><br />
        <input
            type="number"
            v-model="email"
            class="input-field"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
            placeholder="Enter number of emails"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Total Contact </label><br />
        <input
            type="number"
            v-model="totalContact"
            class="input-field"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
            placeholder="Enter total number of contact"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Subject Line</label><br />
        <input
            type="text"
            class="input-field"
            v-model="subjectLine"
            placeholder="Enter Subject Line"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Tag Line</label><br />
        <input
            type="text"
            class="input-field"
            v-model="tagLine"
            placeholder="Enter Tag Line"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
        <label class="label-title">Notes</label><br />
        <textarea
            type="text"
            class="text-area-container"
            v-model="note"
            placeholder="Enter Notes"
        />
      </div>
      <div class="col-xxl-3 col-lg-3 col-md-6 mb-3"></div>
     <div class="col-xxl-6 col-lg-6 col-md-6">
      <div class="search-btn-section button-align">
        <button class="clear-btn" @click="clearCorContract">Clear</button>
        <button class="univ-btn mt-0" @click="addCorContract">Add</button>
         </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import Api from "@/resource/Api";
export default  {
  name: 'AddConContract',
  data () {
    return {
      emailSent: '',
      emailGiven: '',
      sentFrom: '',
      bounce: '',
      zeroBounce: '',
      duplicate: '',
      spam: '',
      totalList: '',
      notUploaded: '',
      unSubscribe: '',
      openRate: '',
      click: '',
      selfRegistration: '',
      phoneCall: '',
      email: '',
      date: '',
      campaignDate: '',
      totalContact: '',
      subjectLine: '',
      tagLine: '',
      note: '',
      isEmailValid: true,
      isCampaignDateValid: true
    }
  },
  methods: {
    addCorContract() {
      let app = this
      if (!app.campaignDate) {
        app.isCampaignDateValid = false
      } else {
        app.isCampaignDateValid = true
        const url = `v2/corcontract/store`;
        let loader = app.$loading.show({
          container: app.$refs.sendReferralLink,
        });
        let params = {
          "email_sent": app.emailSent ? app.emailSent : 0,
          "email_given": app.emailGiven ? app.emailGiven : 0,
          "bounce": app.bounce ? app.bounce : 0,
          "zero_bounce": app.zeroBounce ? app.zeroBounce : 0,
          "duplicate": app.duplicate ? app.duplicate : 0,
          "spam": app.spam ? app.spam : 0,
          "total_list": app.totalList ? app.totalList : 0,
          "not_uploaded": app.notUploaded ? app.notUploaded : 0,
          "unsubscribe": app.unSubscribe ? app.unSubscribe : 0,
          "open_rate": app.openRate ? app.openRate : 0,
          "click": app.click ? app.click : 0,
          "self_registration": app.selfRegistration ? app.selfRegistration : 0,
          "phone_call": app.phoneCall ? app.phoneCall : 0,
          "email": app.email ? app.email : 0,
          "date": app.date ? app.date : "",
          "campaign_date": app.campaignDate ? app.campaignDate : "",
          "sent_from": app.sentFrom ? app.sentFrom : "",
          "subject_line": app.subjectLine ? app.subjectLine : "",
          "tag_line": app.tagLine ? app.tagLine : "",
          "note": app.note ? app.note : ""
        }
        Api.post(url, params)
            .then((res) => {
              this.$notify(res.data.message);
              app.clearCorContract();
              app.$emit('getStatisticsInfo')
            })
            .catch((err) => {
              console.log(err)
              this.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              this.isSend = false;
              loader.hide();
            })
      }
    },
    checkDateValidation () {
      let app = this
      if (app.campaignDate) {
        this.isCampaignDateValid = true;
      } else {
        this.isCampaignDateValid = false;
      }
    },
    clearCorContract () {
      let app = this
      app.isCampaignDateValid = true
      app.emailSent = '',
          app.emailGiven = '',
          app.bounce = '',
          app.zeroBounce = '',
          app.duplicate = '',
          app.spam = '',
          app.totalList = '',
          app.notUploaded = '',
          app.unSubscribe = '',
          app.openRate = '',
          app.click = '',
          app.selfRegistration = '',
          app.phoneCall = '',
          app.email = '',
          app.date = '',
          app.campaignDate = ''
          app.sentFrom = '',
          app.totalContact = '',
          app.subjectLine = '',
          app.tagLine = '',
          app.note = ''
    }
  },
  /*  watch: {
      email(newValue) {
        if (newValue.trim() !== "" && !this.isEmailValidFormat(newValue)) {
          this.isEmailValid = false;
        } else {
          this.isEmailValid = true;
        }
      }
    },*/
}
</script>
<style scoped></style>
