var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid p-0"
  }, [_c('div', {
    staticClass: "top-title"
  }, [_c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Campaign Stats")]), _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "add-btn-data",
    attrs: {
      "title": !_vm.isCollapsed ? 'Show Add Email Detail' : 'Hide Add Email Detail'
    },
    on: {
      "click": _vm.toggleCollapse
    }
  }, [_c('i', {
    staticClass: "fa fa-solid fa-plus"
  })])]), _c('div', {
    staticClass: "btn-grp"
  }, [_c('date-picker', {
    staticClass: "date-field mt-0",
    attrs: {
      "aria-describedby": "input-feedback",
      "placeholder": "MM/DD/YYYY (Campaign Date)",
      "value-type": "format",
      "format": "MM/DD/YYYY"
    },
    model: {
      value: _vm.filter.from,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "from", $$v);
      },
      expression: "filter.from"
    }
  }), _c('date-picker', {
    staticClass: "date-field mt-0",
    attrs: {
      "aria-describedby": "input-feedback",
      "placeholder": "MM/DD/YYYY (Campaign Date)",
      "value-type": "format",
      "format": "MM/DD/YYYY"
    },
    model: {
      value: _vm.filter.to,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "to", $$v);
      },
      expression: "filter.to"
    }
  }), _c('button', {
    staticClass: "date-filter-btn",
    on: {
      "click": _vm.updateInfo
    }
  }, [_vm._v("Filter")]), _c('button', {
    staticClass: "date-filter-btn",
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v("Clear")])], 1)]), _c('div', {
    staticClass: "collapse",
    class: {
      show: _vm.isCollapsed
    }
  }, [_c('add-con-contract', {
    on: {
      "getStatisticsInfo": _vm.updateInfo
    }
  })], 1), _c('div', {
    staticClass: "cor-contract-list-container cor-contract-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-dropdown', {
    staticClass: "export-bg",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('button', {
          staticClass: "export-btn"
        }, [_vm._v("Export "), _c('i', {
          staticClass: "fas fa-angle-down ms-2"
        })])];
      },
      proxy: true
    }, {
      key: "item-content",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('ul', {
    staticClass: "ps-0"
  }, [_c('li', {
    staticClass: "ps-3",
    on: {
      "click": function click($event) {
        return _vm.downloadPdfExcel(_vm.filters, 'pdf');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "csv-export",
    attrs: {
      "title": "Download PDF",
      "download": "",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center ps-0"
  }, [_c('i', {
    staticClass: "fas fa-file-pdf"
  }), _c('p', {
    staticClass: "ms-2 mb-0"
  }, [_vm._v("PDF")])])])]), _c('li', {
    staticClass: "mb-0 ps-1",
    on: {
      "click": function click($event) {
        return _vm.downloadPdfExcel(_vm.filters, 'excel');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "xls-export mb-5",
    attrs: {
      "title": "Download XLS",
      "download": "",
      "target": "_blank"
    }
  }, [_c('div', {}, [_c('img', {
    staticClass: "icon-export xls-container",
    attrs: {
      "src": require("../../assets/images/xls.svg")
    }
  }), _c('span', {
    staticClass: "xls-text"
  }, [_vm._v("XLS")])])])])])])], 1), _c('div', {
    staticClass: "top-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.corContractList,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.corContractList = $event;
      }
    }
  })], 1), _c('cor-contract-list', {
    attrs: {
      "corContractList": _vm.corContractList
    },
    on: {
      "updateCorContractList": _vm.updateInfo
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.corContractList,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.corContractList = $event;
      }
    }
  })], 1), _c('div', [_c('statistics-chats', {
    attrs: {
      "statisticsInfo": _vm.statistics
    }
  })], 1), _c('div', {
    staticClass: "graph-card"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "bar-graph"
  }, [_c('div', {
    staticClass: "email-title"
  }, [_vm._v("Comparison")]), _c('cor-contract-bar-chart')], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "pie-chart"
  }, [_c('pie-chart', {
    attrs: {
      "statisticsData": _vm.statistics
    }
  })], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }