<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th>Campaign Date</th>
        <th>Email Sent</th>
        <th>Open Rate</th>
        <th>Spams</th>
        <th>Bounces</th>
        <th>Clicks</th>
        <th>Unsubscribes</th>
        <th>Registrations</th>
        <th>Phone Calls</th>
        <th>Emails</th>
        <th>Total Contacts</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td v-if="!corContractLength" colspan="6" align="center">
          No records found.
        </td>
      </tr>
      <tr
          :id="item.id"
          v-for="(item, index) in corContractList.records"
          :key="index"
      >
        <td>{{item.campaign_date}}</td>
        <td>{{item.email_sent}}</td>
        <td>{{item.open_rate}}</td>
        <td>{{item.spam}}</td>
        <td>{{item.bounce}}</td>
        <td>{{item.click}}</td>
        <td>{{item.unsubscribe}}</td>
        <td>{{item.self_registration}}</td>
        <td>{{item.phone_call}}</td>
        <td>{{item.email}}</td>
        <td>{{item.total_contact}}</td>
        <td>
          <div class="action-icons">
            <custom-button
                title="Edit Cor Contract"
                iconClass="fas fa-edit"
                @onClicked="openModal(item, 'corContractEditModal')"
            />
            <custom-button
                title="Delete Cor contract"
                iconClass="fa fa-trash"
                className="sign-incomplete"
                @click.native="deleteRow(item.id)"
            />
<!--            <a
                v-b-tooltip.hover
                title="Download PDF"
                download
                target="_blank"
            >
              <img src="@/assets/images/pdf-file.svg" alt="" />
            </a>
            <a
                v-b-tooltip.hover
                title="Download CSV"
                download
                target="_blank"
            >
              <i class="fas fa-file-csv"  style="color: white;"/>
            </a>-->
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <update-email-details-modal  @updateCorContractList="updateCorContractList" :corContractInfo.sync="row"  @onCloseModal="row = null"></update-email-details-modal>
    </div>
  </div>
</template>
<script>
import updateEmailDetailsModal from "../modal/UpdateEmailDetailsModal.vue";
import Api from "@/resource/Api";
export default {
  name: "CorContractList",
  props: {
    corContractList: {
      required: false,
    }
  },
  components: {updateEmailDetailsModal},
  data: () => ({
    row: null
  }),
  computed: {
    corContractLength() {
      return this.corContractList?.records?.length;
    },
  },
  methods: {
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    },
    updateCorContractList () {
      this.$emit('updateCorContractList')
    },
    deleteRow(corContractId) {
      const url = `v2/corcontract/delete/${corContractId}`;
      this.$confirm({
        title: "Delete cor contract email details",
        message: `Do you want to delete this cor contract email details ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
                .then((res) => {
                  let response = res.data.message;
                  this.$notify(response);
                  this.$emit('updateCorContractList')
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, "error");
                })
                .finally(() => {
                  loader.hide();
                });
          }
        },
      });
    },
  },
};
</script>
