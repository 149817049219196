var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "graph-wrap mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Sent Emails: "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_email_sent))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Received Emails : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_email))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Unsubscribe : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_unsubscribe))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Not Uploaded : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_not_uploaded))])]), _c('div', {
    staticClass: "num-detail total-num"
  }, [_vm._v(" Total Email : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.totalEmail))])])])]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_vm._m(1), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Bounce Emails : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_bounce))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v(" Zero Bounce Emails : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_zero_bounce))])]), _c('div', {
    staticClass: "num-detail total-num"
  }, [_vm._v(" Total Bounce Email : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.totalBounce))])])])]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_vm._m(2), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Spam Emails: "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_spam))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Duplicate Emails : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_duplicate))])]), _c('div', {
    staticClass: "num-detail total-num"
  }, [_vm._v(" Total False Email : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.totalDuplicate))])])])]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_vm._m(3), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Open Rate : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.open_rate_percent) + "%")])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Self Registration : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_self_registration))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Click : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_click))])]), _c('div', {
    staticClass: "num-detail"
  }, [_vm._v("Phone Calls : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.total_phone_call))])]), _c('div', {
    staticClass: "num-detail total-num"
  }, [_vm._v("Total : "), _c('span', [_vm._v(_vm._s(_vm.statisticsInfo.totalOthers))])])])])])]), _c('div', {
    staticClass: "graph-wrap mb-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_c('div', {
    staticClass: "email-title mb-3"
  }, [_vm._v("Sent Email")]), _c('SemiCircle', {
    attrs: {
      "statisticsData": [_vm.statisticsInfo.send_email_percent]
    }
  }), _c('div', {
    staticClass: "text-center semi-gauge-tag"
  })], 1)]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_c('div', {
    staticClass: "email-title mb-3"
  }, [_vm._v("Bounce Email")]), _c('SemiCircle', {
    attrs: {
      "statisticsData": [_vm.statisticsInfo.bounce_percent]
    }
  }), _c('div', {
    staticClass: "text-center semi-gauge-tag"
  })], 1)]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_c('div', {
    staticClass: "email-title mb-3"
  }, [_vm._v("Duplicate")]), _c('SemiCircle', {
    attrs: {
      "statisticsData": [_vm.statisticsInfo.duplicate_percent]
    }
  }), _c('div', {
    staticClass: "text-center semi-gauge-tag"
  })], 1)]), _c('div', {
    staticClass: "col-md-3 mb-3"
  }, [_c('div', {
    staticClass: "graph-card"
  }, [_c('div', {
    staticClass: "email-title mb-3"
  }, [_vm._v("Open Rate")]), _c('SemiCircle', {
    attrs: {
      "statisticsData": [_vm.statisticsInfo.open_rate_percent]
    }
  }), _c('div', {
    staticClass: "text-center semi-gauge-tag"
  })], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('h6', {
    staticClass: "me-3 mb-0"
  }, [_vm._v("Email")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('h6', {
    staticClass: "me-3 mb-0"
  }, [_vm._v("Bounce")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('h6', {
    staticClass: "me-3 mb-0"
  }, [_vm._v("Duplicate")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('h6', {
    staticClass: "me-3 mb-0"
  }, [_vm._v("Other")])]);
}]

export { render, staticRenderFns }