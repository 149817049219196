<!-- shows the bar graph comparison of the emails  by choosing option -->
<template>
  <div id="chart">
    <apexchart
        type="bar"
        ref="realtimeChart"
        height="350"
        :options="chartOptions"
        :series="series"
    ></apexchart>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import EventBus from "../../../main";

export default {
  name: "CorContractBarChart",
  data() {
    return {
      dayWiseData: {},
      series: [{
        name: 'Sent Email',
        data: [1, 0, 0, 0, 0],
        color: '#33FF99'
      },
      {
          name: 'Bounce Email',
          data: [1, 0, 0, 0, 0],
          color: '#ff0000'
      },
      {
        name: 'Duplicate Email',
        data: [1, 0, 0, 0, 0],
        color: '#008FFB'
      },
      {
        name: 'Open Rate',
        data: [0, 0, 0, 0, 0],
        color: '#FFA500'
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          },
        },
        xaxis: {
          //type: 'text',
          categories: [],
          labels: {
            formatter: function(val) {
                return new Date(val).toLocaleDateString('en-us', { day:"numeric", month:"short"});
            },
            style: {
                colors: '#000000',
            },
            //datetimeUTC: false, // Do not convert to UTC
        },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },

        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
    }
  },
  mounted () {
    let app = this
    app.barChartData();
    EventBus.$on('bar-graph-render', this.handleCustomEvent);
  },
  methods: {
    barChartData() {
      let url;
      url = 'v2/corcontract/day-wise-data'
      /*eslint-disable*/

     let loader = this.$loading.show();
      Api.get(url)
          .then((res) => {
            let app = this
            let newSeries = app.series
            app.dayWiseData = res.data.data;
            newSeries[0].data =  app.dayWiseData.total_email_sent
            newSeries[1].data =  app.dayWiseData.total_bounce
            newSeries[2].data =  app.dayWiseData.total_duplicate
            newSeries[3].data =  app.dayWiseData.total_open_rate_number
            this.$refs.realtimeChart.updateSeries([{
                    newSeries
                }])

            let  data = app.dayWiseData.campaign_date;
           this.chartOptions = {
                  ...this.chartOptions,
                 xaxis: {
                      categories: data
                    }
            };
            window.dispatchEvent(new Event('resize'));


          })
          .catch(() => {
            this.statistics = {};
            console.log("error");
          })
          .finally(() => {
          loader.hide();
          });
    },
    handleCustomEvent() {
      this.barChartData();
    }
  }
};
</script>
