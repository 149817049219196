<!-- shows the comparison in pie chart -->
<template>
  <div id="chart" v-if="!isObjectEmpty(statisticsData)">
    <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
    ></apexchart>
  </div>
</template>
<script>

export default {
  name: "ConContractPieChart",
  props: {
    statisticsData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        labels: ["Sent Email", "Bounce Email", "Duplicate Emai", "Open Rate"],
        colors: ["#33FF99", "#ff0000", "#008FFB","#FFA500"]
      },
    };
  },
  methods: {
    isObjectEmpty(obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    statisticsData: {
      handler(newData) {
        this.series = [
          newData.totalEmailInd,
          newData.totalBounceInd,
          newData.totalDuplicateInd,
          newData.totalOpenRateInd
        ];
      },
      deep: true
    },
  },
};
</script>
