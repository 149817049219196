<template>
  <div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="top-title">
        <div class="page-title">
          <h1>Campaign Stats</h1>
          <button
            @click="toggleCollapse"
            v-b-tooltip.hover
            :title="!isCollapsed ? 'Show Add Email Detail': 'Hide Add Email Detail'"
            class="add-btn-data"

          >
            <!-- <img src="../assets/images/filter-new.svg" /> -->
            <i class="fa fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="btn-grp">

            <date-picker
                aria-describedby="input-feedback"
                class="date-field mt-0"
                placeholder="MM/DD/YYYY (Campaign Date)"
                v-model="filter.from"
                value-type="format"
                format="MM/DD/YYYY"
            />
            <date-picker
                class="date-field mt-0"
                aria-describedby="input-feedback"
                placeholder="MM/DD/YYYY (Campaign Date)"
                v-model="filter.to"
                value-type="format"
                format="MM/DD/YYYY"
            />
            <button @click="updateInfo" class="date-filter-btn">Filter</button>
            <button @click="clearFilter" class="date-filter-btn">Clear</button>
        </div>
      </div>
<!--      <div class="filter-btn-container">
        <div class="search-btn-section">
          <button class="filter-clear-btn" @click="clearCorContract">Clear</button>
          <button class="univ-btn mt-0" @click="addCorContract">Add</button>
           </div>
        </div>
      </div>-->
      <!-- Collapsible div -->
      <div class="collapse" :class="{ show: isCollapsed }">
        <add-con-contract @getStatisticsInfo="updateInfo"></add-con-contract>
      </div>

      <div class="cor-contract-list-container cor-contract-card">
        <div class="d-flex justify-content-end">
          <b-dropdown class="export-bg">
                  <template #button-content>
                    <button class="export-btn">Export <i class="fas fa-angle-down ms-2"></i></button>
                  </template>
                 <template #item-content>
                  </template>
                    <ul class="ps-0">
                      <li class="ps-3" @click="downloadPdfExcel(filters, 'pdf')">
                        <a
                            v-b-tooltip.hover
                            title="Download PDF"
                            download
                            target="_blank"
                            class="csv-export"
                        >
                        <div class="d-flex align-items-center ps-0">
                          <i class="fas fa-file-pdf"></i><p class="ms-2 mb-0">PDF</p>
                        </div>
                      </a>
                      </li>
                      <li class="mb-0 ps-1" @click="downloadPdfExcel(filters, 'excel')">
                        <a
                          v-b-tooltip.hover
                          title="Download XLS"
                          download
                          target="_blank"
                          class="xls-export mb-5"
                      >
                          <div class="">
                            <img class="icon-export xls-container" src="../../assets/images/xls.svg"  />
                            <span class="xls-text">XLS</span>
                          </div>
                        </a>
                      </li>
                    </ul>
            </b-dropdown>
        </div>
        <div class="top-pagination">
          <pagination :rows.sync="corContractList" :filters="filterQuery" />
        </div>
        <cor-contract-list @updateCorContractList="updateInfo" :corContractList="corContractList"></cor-contract-list>
        <pagination :rows.sync="corContractList" :filters="filterQuery" />
      </div>

      <div>
        <statistics-chats  :statisticsInfo="statistics"/>
      </div>

      <!-- bar graph -->
      <div class="graph-card">
        <div class="row">
          <div class="col-md-8">
            <div class="bar-graph">
              <div class="email-title">Comparison</div>
              <cor-contract-bar-chart />
            </div>
          </div>
          <div class="col-md-4">
            <div class="pie-chart">
              <pie-chart :statisticsData="statistics"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CorContractBarChart from "./chats/CorContractBarChart.vue";
import PieChart from "./chats/CorContractPieChart.vue";
import AddConContract from "./partials/AddCorContract.vue";
import statisticsChats from "./partials/StatisticsChats.vue";
import Api from "@/resource/Api";
import CorContractList from "./partials/CorContractList.vue";
import axios from "axios";
// import Helper from "@/resource/Helper";
import EventBus from "../../main";

const filterData = {};
export default {
  name: "CorContractView",
  components: { statisticsChats, CorContractBarChart, PieChart, AddConContract, CorContractList },
  data() {
    return {
      show: false,
      isCollapsed: false,
      statistics: {},
      filter: {
        from: '',
        to: ''
      },
      corContractList: [],
      filterQuery: { ...filterData }
    };
  },
  mounted () {
    let app = this
    app.getCorContractList();
    app.getStatisticsInfo ();
    app.getbarGraphdata()
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getbarGraphdata() {
      EventBus.$emit('bar-graph-render');
    },
    clearFilter() {
      this.filter.from = ''
      this.filter.to = ''
      this.updateInfo()
    },
    updateInfo () {
      let app = this
      app.getStatisticsInfo();
      app.getCorContractList();
      app.getbarGraphdata();
    },
    getStatisticsInfo () {
      this.checkForDateNullFormat();
      let url;
      url = 'v2/corcontract/get'
      url+= "?from="+this.filter.from+"&to="+this.filter.to
      let loader = this.$loading.show();
      Api.get(url)
          .then((res) => {
            let app = this
            app.statistics = res.data.data;
            app.calculateStatisticsTotal();

          })
          .catch((err) => {
            this.statistics = {};
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            loader.hide();
          });
    },
    checkForDateNullFormat () {
      this.filter.from = this.filter.from || '';
      this.filter.to = this.filter.to || '';
    },
    calculateStatisticsTotal() {
      let app = this;
      let totalSendEmailInd = parseInt(app.statistics.total_email_sent)
      let totalBounceInd = parseInt(app.statistics.total_bounce)
      let totalDuplicateInd = parseInt(app.statistics.total_duplicate);
      let totalOpenRate = parseFloat(app.statistics.total_open_rate);
      let totalOpenRateInd = parseInt(Math.ceil(app.statistics.total_open_rate_number));

      let totalEmail = parseInt(app.statistics.total_email_sent) +
          parseInt(app.statistics.total_email) +
          parseInt(app.statistics.total_unsubscribe) +
          parseInt(app.statistics.total_not_uploaded);
      let totalBounce = parseInt(app.statistics.total_bounce) +
          parseInt(app.statistics.total_zero_bounce);
      let totalDuplicate = parseInt(app.statistics.total_spam) +
          parseInt(app.statistics.total_duplicate);

      let others = parseFloat(app.statistics.total_self_registration) +
          parseFloat(app.statistics.total_click) +
          parseFloat(app.statistics.total_phone_call);

      app.statistics['totalEmail'] = totalEmail;
      app.statistics['totalBounce'] = totalBounce;
      app.statistics['totalDuplicate'] = totalDuplicate;
      app.statistics['totalOthers'] = others;
      app.statistics['totalEmailInd'] = totalSendEmailInd;
      app.statistics['totalBounceInd'] = totalBounceInd;
      app.statistics['totalDuplicateInd'] = totalDuplicateInd;
      app.statistics['totalOpenRate'] = totalOpenRate;
      app.statistics['totalOpenRateInd'] = totalOpenRateInd;


    },
    clearCorContract () {
      console.log('it clear form')
    },
    addCorContract () {
      console.log('it add cor contract')
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getCorContractList(this.filterQuery);
    },
    downloadPdfExcel (filters = [], fileType) {
      let url;
      this.filterQuery = this.filter
      if (filters) {
        url = `v2/corcontract/export?from=${this.filter.from}&to=${this.filter.to}&format=${fileType}`;
      } else {
        url = 'v2/corcontract/export'
        url+= "?from="+this.filter.from+"&to="+this.filter.to+"&format="+fileType
      }
      let loader = this.$loading.show();
      axios.get(url)
        .then((res) => {
          console.log(res)
          window.open(res.data.data.file_url, '_blank');
        })
        .finally(() => {
          loader.hide();
        });
    },
    getCorContractList (filters = []) {
      let url;
      this.filterQuery = this.filter
      if (filters) {
       // let filterUrl = Helper.objToUrlParams(filters);
        // url = `v2/corcontract/list?from=${this.filter.from}&to=${this.filter.to}?${filterUrl}`;
        url = `v2/corcontract/list?from=${this.filter.from}&to=${this.filter.to}`;
      } else {
        url = 'v2/corcontract/list'
        url+= "?from="+this.filter.from+"&to="+this.filter.to
      }
      let loader = this.$loading.show();
      axios
          .get(url)
          .then((res) => {
            this.corContractList = res.data;
          })
          .finally(() => {
            loader.hide();
          });
    }
  },
};
</script>
<style src="../../assets/css/corcontract.css">
</style>
