var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isObjectEmpty(_vm.statisticsData) ? _c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "donut",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }