<template>
  <div>
    <!-- total number cards -->
    <div class="graph-wrap mt-4">
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="d-flex align-items-center mb-2">
              <h6 class="me-3 mb-0">Email</h6>
<!--              <span class="tag"><img src="../../../assets/images/up-arrow.svg"/>15%</span>-->
            </div>
            <div class="num-detail">Sent Emails: <span>{{statisticsInfo.total_email_sent}}</span></div>
            <div class="num-detail">Received Emails : <span>{{statisticsInfo.total_email}}</span></div>
            <div class="num-detail">Unsubscribe : <span>{{statisticsInfo.total_unsubscribe}}</span></div>
            <div class="num-detail">Not Uploaded : <span>{{statisticsInfo.total_not_uploaded}}</span></div>
            <div class="num-detail total-num">
              Total Email : <span>{{statisticsInfo.totalEmail}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="d-flex align-items-center mb-2">
              <h6 class="me-3 mb-0">Bounce</h6>
<!--              <span class="down-tag"><img src="../../../assets/images/down-arrow-rate.svg"/>15%</span>-->
            </div>
            <div class="num-detail">Bounce Emails : <span>{{statisticsInfo.total_bounce}}</span></div>
            <div class="num-detail">
              Zero Bounce Emails : <span>{{statisticsInfo.total_zero_bounce}}</span>
            </div>
            <div class="num-detail total-num">
              Total Bounce Email : <span>{{statisticsInfo.totalBounce}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="d-flex align-items-center mb-2">
              <h6 class="me-3 mb-0">Duplicate</h6>
<!--              <span class="tag"><img src="../../../assets/images/up-arrow.svg"/>15%</span>-->
            </div>
            <div class="num-detail">Spam Emails: <span>{{statisticsInfo.total_spam}}</span></div>
            <div class="num-detail">Duplicate Emails : <span>{{statisticsInfo.total_duplicate}}</span></div>
            <div class="num-detail total-num">
              Total False Email : <span>{{statisticsInfo.totalDuplicate}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="d-flex align-items-center mb-2">
              <h6 class="me-3 mb-0">Other</h6>
<!--              <span class="down-tag"><img src="../../../assets/images/down-arrow-rate.svg"/>15%</span>-->
            </div>
            <div class="num-detail">Open Rate : <span>{{statisticsInfo.open_rate_percent}}%</span></div>
            <div class="num-detail">Self Registration : <span>{{statisticsInfo.total_self_registration}}</span></div>
            <div class="num-detail">Click : <span>{{statisticsInfo.total_click}}</span></div>
            <div class="num-detail">Phone Calls : <span>{{statisticsInfo.total_phone_call}}</span></div>
            <div class="num-detail total-num">Total : <span>{{statisticsInfo.totalOthers}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- pictorial diagrams section -->
    <div class="graph-wrap mb-4">
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="email-title mb-3">Sent Email</div>
            <SemiCircle :statisticsData="[statisticsInfo.send_email_percent]" />
            <div class="text-center semi-gauge-tag">
<!--              <span class="tag"><img src="../../../assets/images/up-arrow.svg"/>15%</span>-->
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="email-title mb-3">Bounce Email</div>
            <SemiCircle :statisticsData="[statisticsInfo.bounce_percent]"/>
            <div class="text-center semi-gauge-tag">
<!--              <span class="down-tag"><img src="../../../assets/images/down-arrow-rate.svg"/>15%</span>-->
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="email-title mb-3">Duplicate</div>
            <SemiCircle :statisticsData="[statisticsInfo.duplicate_percent]" />
            <div class="text-center semi-gauge-tag">
<!--              <span class="tag"><img src="../../../assets/images/up-arrow.svg"/>15%</span>-->
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="graph-card">
            <div class="email-title mb-3">Open Rate</div>
            <SemiCircle :statisticsData="[statisticsInfo.open_rate_percent]" />
            <div class="text-center semi-gauge-tag">
<!--              <span class="down-tag"><img src="../../../assets/images/down-arrow-rate.svg"/>15%</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SemiCircle from "../chats/CorContractSemiCircle.vue";

export default  {
  name: 'StatisticsChats',
  props: {
    statisticsInfo: {
      required: true
    }
  },
  components: {SemiCircle}
}
</script>
