<template>
  <div>
    <b-modal
        id="corContractEditModal"
        modal-class="cor-contract-edit-modal"
        size="xl"
        hide-footer
        @shown="getEmailDetailInfo()">
      <template #modal-header>
        <div class="cor-contract-header">
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid ref="planTerminationContainer">
        <div>
          <div class="row">
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Campaign Date</label>
              <span class="required-asterisk">*</span>
              <br />
              <date-picker
                  aria-describedby="input-feedback"
                  class="date-picker-text"
                  placeholder="MM/DD/YYYY"
                  :class="{'mx-input-invalid': !isCampaignDateValid}"
                  @change="checkDateValidation"
                  v-model="campaignDate"
                  value-type="format"
                  format="MM/DD/YYYY"
              >
              </date-picker>
              <div v-if="!isCampaignDateValid" class="error-message">Field is required</div>
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Date Given</label><br />
              <date-picker
                  aria-describedby="input-feedback"
                  placeholder="MM/DD/YYYY"
                  class="date-picker-text"
                  v-model="date"
                  value-type="format"
                  format="MM/DD/YYYY"
              >
              </date-picker>
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Emails Given </label><br />
              <input
                  type="number"
                  v-model="emailGiven"
                  class="input-field"
                  placeholder="Enter number of emails given"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Sent From </label><br />
              <input
                  type="text"
                  v-model="sentFrom"
                  class="input-field"
                  placeholder="Enter sent from"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Zero Bounce</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="zeroBounce"
                  placeholder="Enter number of zero bounces"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Duplicates</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="duplicate"
                  placeholder="Enter number of duplicates"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Net Uploaded </label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="notUploaded"
                  placeholder="Enter net uploaded  number"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Total List</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="totalList"
                  placeholder="Enter total list number"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">How many emails sent? </label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="emailSent"
                  placeholder="Enter number of emails sent"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Bounces</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="bounce"
                  placeholder="Enter number of bounces"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Spams</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="spam"
                  placeholder="Enter number of spams"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Unsubscribes</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="unSubscribe"
                  placeholder="Enter Unsubscribed numbers"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Open Rate (%)</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="openRate"
                  placeholder="Enter open rate %"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Clicks</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="click"
                  placeholder="Enter number of clicks"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Self-Registrations</label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="selfRegistration"
                  placeholder="Enter Registration number"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Phone Calls </label><br />
              <input
                  type="number"
                  class="input-field"
                  v-model="phoneCall"
                  placeholder="Enter number of phone calls"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Emails </label><br />
              <input
                  type="number"
                  v-model="email"
                  class="input-field"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
                  placeholder="Enter number of emails"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Total Contact </label><br />
              <input
                  type="number"
                  v-model="totalContact"
                  class="input-field"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').substring(0, 12)"
                  placeholder="Enter total number of contact"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Subject Line</label><br />
              <input
                  type="text"
                  class="input-field"
                  v-model="subjectLine"
                  placeholder="Enter Subject Line"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Tag Line</label><br />
              <input
                  type="text"
                  class="input-field"
                  v-model="tagLine"
                  placeholder="Enter Tag Line"
              />
            </div>
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
              <label class="label-title">Notes</label><br />
              <textarea
                  type="text"
                  class="text-area-container"
                  v-model="note"
                  placeholder="Enter Notes"
              />
            </div>
          </div>
          <div class="update-btn-container mt-3">
            <div class="col-xxl-6 col-lg-6 col-md-6">
              <button class="univ-btn mt-0" @click="updateCorContract">Update</button>
            </div>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>


<script>
import Api from "@/resource/Api";
export default  {
  name: 'UpdateEmailDetailsModal',
  props: {
    corContractInfo: {
      required: true,
    }
  },
  data () {
    return {
      emailSent: '',
      emailGiven: '',
      sentFrom: '',
      bounce: '',
      zeroBounce: '',
      duplicate: '',
      spam: '',
      totalList: '',
      notUploaded: '',
      unSubscribe: '',
      openRate: '',
      click: '',
      selfRegistration: '',
      phoneCall: '',
      email: '',
      date: '',
      campaignDate: '',
      totalContact: '',
      isEmailValid: true,
      isCampaignDateValid: true,
      subjectLine: '',
      tagLine: '',
      note: ''
    }
  },
  methods: {
    updateCorContract() {
      let app = this
      if (!app.campaignDate) {
        app.isCampaignDateValid = false
      } else {
        app.isCampaignDateValid = true
        const url = `v2/corcontract/store`;
        let loader = app.$loading.show({
          container: app.$refs.sendReferralLink,
        });
        let params = {
          "email_sent": app.emailSent ? app.emailSent : 0,
          "email_given": app.emailGiven ? app.emailGiven : 0,
          "bounce": app.bounce ? app.bounce : 0,
          "zero_bounce": app.zeroBounce ? app.zeroBounce : 0,
          "duplicate": app.duplicate ? app.duplicate : 0,
          "spam": app.spam ? app.spam : 0,
          "total_list": app.totalList ? app.totalList : 0,
          "not_uploaded": app.notUploaded ? app.notUploaded : 0,
          "unsubscribe": app.unSubscribe ? app.unSubscribe : 0,
          "open_rate": app.openRate ? app.openRate : 0,
          "click": app.click ? app.click : 0,
          "self_registration": app.selfRegistration ? app.selfRegistration : 0,
          "phone_call": app.phoneCall ? app.phoneCall : 0,
          "email": app.email ? app.email : 0,
          "date": app.date ? app.date : "",
          "campaign_date": app.campaignDate ? app.campaignDate : "",
          "sent_from": app.sentFrom ? app.sentFrom : "",
          "total_contact": app.totalContact ? app.totalContact : 0,
          "id": app.corContractInfo.id,
          "subject_line": app.subjectLine ? app.subjectLine : "",
          "tag_line": app.tagLine ? app.tagLine : "",
          "note": app.note ? app.note : ""
        }
        Api.post(url, params)
            .then((res) => {
              this.$notify(res.data.message);
              app.$emit('updateCorContractList')
              app.closeModal();
            })
            .catch((err) => {
              console.log(err)
              this.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              this.isSend = false;
              loader.hide();
            })
      }
    },
    checkDateValidation () {
      let app = this
      if (app.campaignDate) {
        this.isCampaignDateValid = true;
      } else {
        this.isCampaignDateValid = false;
      }
    },
    closeModal() {
      this.$emit("onCloseModal");
      this.isCampaignDateValid = true;
      this.$bvModal.hide("corContractEditModal");
    },
    getEmailDetailInfo (){
      let url;
      url = `v2/corcontract/detail/${this.corContractInfo.id}`
      let loader = this.$loading.show();
      Api.get(url)
          .then((res) => {
            let app = this
            let data = res.data.data
            app.emailSent = data.email_sent,
                app.emailGiven = data.email_given
            app.sentFrom = data.sent_from
            app.bounce = data.bounce
            app.zeroBounce = data.zero_bounce
            app.duplicate = data.duplicate
            app.spam = data.spam
            app.totalList = data.total_list
            app.notUploaded = data.not_uploaded
            app.unSubscribe = data.unsubscribe
            app.openRate = data.open_rate
            app.click = data.click
            app.selfRegistration = data.self_registration
            app.email = data.email
            app.date = data.date
            app.totalContact = data.total_contact,
            app.campaignDate = data.campaign_date
            app.phoneCall = data.phone_call,
            app.subjectLine = data.subject_line
            app.tagLine = data.tag_line
            app.note = data.note
          })
          .catch(() => {
            this.statistics = {};
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });
    }
  }
}
</script>
<style scoped>
</style>
